// Dependencies
import { combineReducers } from 'redux';
// Reducers
import { auth } from './auth';
import { user } from './user';
import { steps } from './steps';

const reducers = combineReducers({
  auth,
  user,
  steps,
});

export default reducers;
