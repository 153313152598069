// Types
import { StepsTypes } from '../types';

const INITIAL_STATE = {
  steps: [],
  step: 1,
};

// eslint-disable-next-line default-param-last
export const steps = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StepsTypes.saveStepData:
      return {
        ...state,
        steps: action.payload,
        step: state.step + 1,
      };
    case StepsTypes.getStepUser:
      return {
        ...state,
        step: action.payload,
      };
    default:
      return state;
  }
};
