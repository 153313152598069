// Dependencies
import { lazy } from 'react';
// Views
const Profile = lazy(() => import('../views/Profile/container'));
const Form = lazy(() => import('../views/Form/container'));
const Login = lazy(() => import('../views/Login/container'));
const SignUp = lazy(() => import('../views/SingUp/container'));
const ResultAirplane = lazy(() => import('../views/Results/ResultsAirplane'));
const ComputeData = lazy(() => import('../views/ComputeData/container'));

const routes = {
  private: [
    {
      path: '/profile',
      key: 'profile',
      element: Profile,
      title: 'Profile',
    },
    {
      path: '/',
      key: 'form',
      element: Form,
      title: 'Form',
    },
    {
      path: '/compute-data',
      key: 'ComputeData',
      element: ComputeData,
      title: 'Compute Data',
    },
    {
      path: '/resultado',
      key: 'result',
      element: ResultAirplane,
      title: 'result',
    },
  ],
  public: [
    {
      path: '/login',
      key: 'Login',
      element: Login,
      title: 'Login',
    },
    {
      path: '/signup',
      key: 'SignUp',
      element: SignUp,
      title: 'SignUp',
    },

  ],
};

export default routes;
