// Dependencies
import { Suspense } from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
// Middlewares
import { isAuthFn } from '../middlewares';
// Routes
import routes from './routes';
// import Header from '../components/header';
// Styles
import css from './css.module.scss';

export function Navigation() {
  return (
    <Suspense
      fallback={(
        <div className={css.containerFallback}>
          <FaSpinner className={css.loadingIcon} />
        </div>
)}
    >
      <BrowserRouter>
        <div className="main-layout">
          <Routes>
            {routes.public.map(({
              element: Element, key, path, ...props
            }) => (
              <Route key={key} path={path} element={<Element />} {...props} />
            ))}

            {routes.private.map(({
              element: Element, path, key, ...props
            }) => {
              const auth = isAuthFn();

              if (auth) {
                return (
                  <Route path={path} key={key} element={<Element />} {...props} />
                );
              }

              return <Route key={key} path={path} element={<Navigate to="/login" replace />} />;
            })}

            <Route path="/*" element={<h1>Not Found Element</h1>} />

          </Routes>
        </div>
      </BrowserRouter>
    </Suspense>
  );
}
